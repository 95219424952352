import React from 'react';
import './App.css';

function App() {
  return (
    <div className="Background">
      <nav id="navbar">
        <div className='nav-wrapper'>
        <img src='https://storage.googleapis.com/z_static/Dark%20Logo.png' alt="Logo" className="logo-image"></img>
          </div>
      </nav>
    </div>
  );
}

export default App;
